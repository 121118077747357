export const real_op = {
        locale: "pt-BR",
        prefix: "R$",
        suffix: "",
        length: 30,
        precision: 2
}

export const real_ha_op = {
        locale: "pt-BR",
        prefix: "R$/ha",
        suffix: "",
        length: 30,
        precision: 2
}

export const dollar_op  = {
        locale: "en",
        prefix: "$",
        suffix: "",
        length: 30,
        precision: 2
}

export const real_op_suf = {
        locale: "pt-BR",
        prefix: "",
        suffix: "R$/ha",
        length: 30,
        precision: 2
}

export const sack_op_suf = {
        locale: "pt-BR",
        prefix: "",
        suffix: "",
        length: 30,
        precision: 2
}
