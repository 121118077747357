<template>
  <v-card id="budget-form" class="mx-auto mt-4" elevation="0">
    <div class="text-center">
      <v-overlay :value="overlay">
        <div
          class="pa-5 rounded-lg"
        >
          <v-progress-circular indeterminate size="64" color="accent" />
          <h1>Salvando sua previsão orçamentária...</h1>
        </div>
      </v-overlay>
    </div>
    <v-banner
      class="font-weight-bold ex-banner pb-2"
      :class="$vuetify.theme.isDark ? 'background' : 'white'"
      single-line
      sticky
      width="100%"
    >
      <v-row class="budget-row">
        <v-col cols="3" sm="3" md="3" lg="3" xl="3">
          <v-select
            v-model="ownershipSelected"
            :items="ownerships"
            color="primary"
            label="Propriedade"
            item-text="name"
            item-value="id"
            no-data-text="Nenhuma propriedade encontrada"
            hide-details
          />
        </v-col>
        <v-col cols="2" sm="2" md="3" lg="3" xl="3">
          <v-select
            v-model="growingSelected"
            :items="growings"
            color="primary"
            label="Cultura"
            item-text="name"
            item-value="id"
            return-object
            hide-details
          />
        </v-col>
        <v-col cols="2" sm="3" md="2" lg="2" xl="3">
          <v-select
            v-if="cattleSelected"
            v-model="cattleLotsSelected"
            :items="cattleLotOwnership"
            color="primary"
            label="Lote"
            item-text="name"
            item-value="id"
            return-object
            hide-details
          />
          <agr-harvest-autocomplete
            v-else
            v-model="harvestSelected"
            :items="harvests"
            return-object
            hide-details
            />
        </v-col>
        <v-col cols="3" sm="4" md="4" lg="4" xl="3" align="right">
          <v-btn
            large
            color="primary"
            class="hidden-md-and-down"
            rounded
            :loading="loadingReport"
            @click="getReport()"
          >
            Gerar Relatório PDF<v-icon class="px-2"
              >mdi-file-chart-outline</v-icon
            >
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon large light color="accent">mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>

          <v-btn
            color="primary"
            class="hidden-lg-and-up"
            rounded
            :loading="loadingReport"
            @click="getReport()"
          >
            Relatório PDF<v-icon class="px-1">mdi-file-chart-outline</v-icon>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon large light color="accent">mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
    </v-banner>

    <!-- TABLE -->
    <div class="pt-0 section align-center">
      <v-row>
        <v-col sm="12" md="12" lg="12" xl="12">
          <v-container fluid tag="section">
            <v-row
              v-if="loading"
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col class="subtitle-1 text-center" cols="12">
                Buscando informações
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>

            <div v-else>
              <POP
                v-if="cattleSelected"
                :ownershipSelected="ownershipSelected"
                :growingSelected="growingSelected"
                :cattleLotsSelected="cattleLotsSelected"
                :ownershipReference="getOwnershipReference"
                @setOverlay="overlay = $event"
                @ready="childReady = $event"
              />
              <POS
                v-else
                :ownershipSelected="ownershipSelected"
                :harvestSelected="harvestSelected"
                :growingSelected="growingSelected"
                :ownershipReference="getOwnershipReference"
                @setOverlay="overlay = $event"
                @ready="childReady = $event"
              />
            </div>
          </v-container>
        </v-col>
      </v-row>
    </div>

    <div v-if="childReady" id="save-btn">
      <v-btn
        fab
        x-large
        dark
        bottom
        class="v-btn--save"
        color="primary"
        @click="clickOnsave"
      >
        <div>
          <v-icon medium>mdi-content-save</v-icon><br />
          <span>Salvar</span>
        </div>
      </v-btn>
    </div>

    <v-dialog
      v-model="dialog"
      mix-height="90vh"
      max-width="50vw"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="close"
    >
      <v-card pt-3>
        <v-card-title
          class="form-header headline pa-2 font-weight-medium"
          primary-title
        >
          Propriedade
        </v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="text-center">
              <span class="py-2 green-1 text-h3">
                Para realizar a previsão orçamentária, primeiro cadastre uma
                propriedade.
              </span>
            </v-col>
          </v-row>
          <v-row justify="end" no-gutters>
            <v-col class="text-right">
              <v-btn class="ma-1" color="primary" to="/crud/ownership">
                Ok!
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";
import { reportService } from "@/services/reports";
import { organizationThemes } from "@/plugins/theme/agrinvest";

import POS from "./budget_tables/POS";
import POP from "./budget_tables/POP";

export default {
  components: {
    POS: POS,
    POP: POP,
  },
  data() {
    return {
      // open form
      dialog: false,
      // Propriedade selecionada
      ownershipSelected: undefined,
      // obj cultura selecionado
      growingSelected: { name: "" },
      // obj safra selecionado
      harvestSelected: undefined,
      // obj Lote boi selecionado
      cattleLotsSelected: undefined,
      // array de propriedades
      ownerships: [],
      // array de lotes
      cattle_lots: [],
      // load pagina
      loading: false,
      // load btn de report
      loadingReport: false,
      // constantes das culturas
      constants: undefined,
      // overlay para salvar budgets
      overlay: false,
      // Event emitido pelas tabela
      // para informar quando seu carregamento terminou
      // e aparecer o btn de salvar
      childReady: false,
    };
  },
  async created() {
    this.loading = true;
    // Seleciona safra atual
    this.harvestSelected = this.harvests.find((h) => h.is_current);
    // Seleciona a cultura Soja
    this.growingSelected = this.growings.find((g) => g.symbol === "S");
    let result = await this.myLots();
    if (result.length > 0) {
      this.cattle_lots = result;
      this.cattleLotsSelected = this.cattle_lots[0];
    }
    result = await this.myOwnerships();
    if (result.length > 0) {
      this.ownerships = result;
      this.ownershipSelected = this.ownerships[0].id;
      this.loading = false;
    } else {
      this.dialog = true;
    }
  },
  computed: {
    ...mapGetters({
      growings: "getGrowings",
      harvests: "getHarvests",
    }),
    getOwnershipReference() {
      if (this.ownershipSelected) {
        let owner = this.ownerships.find(
          (o) => o.id === this.ownershipSelected
        );
        if (this.growingSelected.symbol === "S")
          return owner.deal_soybean_reference;
        if (this.growingSelected.symbol === "C")
          return owner.deal_corn_reference;
        if (this.growingSelected.symbol === "CT")
          return owner.deal_cotton_reference;
      }
      return undefined;
    },
    cattleSelected() {
      return this.growingSelected.symbol === "BC";
    },
    cattleLotOwnership() {
      if (this.cattle_lots.length > 0)
        return this.cattle_lots.filter(
          (cl) => cl.ownership_id === this.ownershipSelected
        );
      return [];
    },
  },
  methods: {
    ...mapActions({
      myOwnerships: "MY_OWNERSHIPS",
      myLots: "MY_LOTS",
    }),
    agr_alert(msg, isSuccess = true) {
      isSuccess ? this.$alertSuccess(msg) : this.$alertError(msg);
    },
    async getReport() {
      if (this.growingSelected.symbol != "BC") {
        try {
          this.loadingReport = true;
          let response = await reportService.getSyncReportSimpleBudget(
            this.growingSelected.id,
            this.harvestSelected.id,
            this.ownershipSelected,
            "pdf"
          );
          // let blob = new Blob([response.data], { type: 'application/pdf' });
          // let url = window.URL.createObjectURL(blob);
          // window.open(url);
          let fileURL = window.URL.createObjectURL(
            new Blob([response], { type: "application/pdf" })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "relatorio.pdf");
          document.body.appendChild(fileLink);
          // fileLink.click();
          window.open(fileURL);
          this.loadingReport = false;
        } catch (error) {
          this.agr_alert("Houve um problema ao carregar o relatório", false);
          console.error(error);
          this.loadingReport = false;
        }
      } else {
        try {
          this.loadingReport = true;
          let response = await reportService.getSyncReportSimpleBudgetCattle(
            this.cattleLotsSelected.id,
            "pdf"
          );
          // let blob = new Blob([response.data], { type: 'application/pdf' });
          // let url = window.URL.createObjectURL(blob);
          // window.open(url);
          let fileURL = window.URL.createObjectURL(
            new Blob([response], { type: "application/pdf" })
          );
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "relatorio.pdf");
          document.body.appendChild(fileLink);
          // fileLink.click();
          window.open(fileURL);
          this.loadingReport = false;
        } catch (error) {
          this.agr_alert("Houve um problema ao carregar o relatório", false);
          console.error(error);
          this.loadingReport = false;
        }
      }
    },
    clickOnsave() {
      if (this.cattleSelected) eventBus.$emit("save-budget-pop");
      else eventBus.$emit("save-budget-pos");
    },
  },

  beforeDestroy() {
    this.$vuetify.theme.themes.light = organizationThemes.agrinvest.find((el) => {
      return el.name === "soybean";
    }).light;
    this.$vuetify.theme.themes.dark = organizationThemes.agrinvest.find((el) => {
      return el.name === "soybean";
    }).dark;
  },

  watch: {
    cattleSelected(val) {
      if (val) {
        eventBus.$emit("change-budget-POP");

        // change theme light
        this.$vuetify.theme.themes.light = organizationThemes.agrinvest.find((el) => {
          return el.name === "cattle";
        }).light;
        // change theme dark
        this.$vuetify.theme.themes.dark = organizationThemes.agrinvest.find((el) => {
          return el.name === "cattle";
        }).dark;
      } else eventBus.$emit("change-budget-POS");
    },
    ownershipSelected() {
      if (this.cattleSelected) {
        if (this.cattleLotOwnership && this.cattleLotOwnership.length > 0) {
          this.cattleLotsSelected = this.cattleLotOwnership[0];
        } else {
          this.cattleLotsSelected = undefined;
        }
      }
    },

    growingSelected(val) {
      switch (val.symbol) {
        case "S":
          this.$vuetify.theme.themes.light = organizationThemes.agrinvest.find((el) => {
            return el.name === "soybean";
          }).light;
          this.$vuetify.theme.themes.dark = organizationThemes.agrinvest.find((el) => {
            return el.name === "soybean";
          }).dark;
          break;

        case "C":
          this.$vuetify.theme.themes.light = organizationThemes.agrinvest.find((el) => {
            return el.name === "corn";
          }).light;
          this.$vuetify.theme.themes.dark = organizationThemes.agrinvest.find((el) => {
            return el.name === "corn";
          }).dark;
          break;

        case "CT":
          this.$vuetify.theme.themes.light = organizationThemes.agrinvest.find((el) => {
            return el.name === "cotton";
          }).light;
          this.$vuetify.theme.themes.dark = organizationThemes.agrinvest.find((el) => {
            return el.name === "cotton";
          }).dark;
          break;

        case "BC":
          this.$vuetify.theme.themes.light = organizationThemes.agrinvest.find((el) => {
            return el.name === "cattle";
          }).light;
          this.$vuetify.theme.themes.dark = organizationThemes.agrinvest.find((el) => {
            return el.name === "cattle";
          }).dark;
          break;

        default:
          this.$vuetify.theme.themes.light = organizationThemes.agrinvest.find((el) => {
            return el.name === "soybean";
          }).light;
          this.$vuetify.theme.themes.dark = organizationThemes.agrinvest.find((el) => {
            return el.name === "soybean";
          }).dark;

          break;
      }
    },
  },
};
</script>

<style lang="sass">

  #budget-form
    table
      border-collapse: collapse

    .table-header
      font-size: 16px
      font-weight: bold

    .budget-custom
      border: none
      width: 100%

    .budget-custom th
      height: 50px
      background-color: var(--v-primary-base)
      color: white
      padding: 0.5em 1em
      //FIXED HEADER
      position: -webkit-sticky // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky
      top: 80px
      z-index: 5

    .budget-custom tr:hover
      background-color: #c1c1c180
      border-radius: 10px 0px 0px 10px

    .budget-custom tr
      border-bottom: 1.2px solid #c1c1c180

    .budget-custom tr:last-child
      border: 0
    .budget-custom tr:first-child
      border: 0

    .grouper
      font-weight: bold
      background-color: #C0C0C080
      border-radius: 10px 0 0 10px

    .budget-custom td
      // border-style: solid
      // border-bottom-color: #c1c1c180
      border-top-width: 0
      border-left-width: 0
      border-right-width: 0
      border-bottom-width: 0
      padding: 0.5em 1em

    .col-itens
      width: 20%
      text-align: left
      font-weight: 600

    .col-date
      width: 12%
      text-align: center
      font-weight: 600

    .col-cost
      width: 10%
      text-align: center
      font-weight: 600

    .col-currency
      width: 10%
      text-align: center
      font-weight: 600

    .col-actions
      width: 10%
      font-weight: 600

    .col-brl
      width: 10%
      font-weight: 600

    .col-usd
      width: 10%
      font-weight: 600

    .col-growing
      width: 10%
      font-weight: 600

    .custom-input input
      cursor: pointer

    .mdi-menu-down::before
      cursor: pointer

    .mdi-menu-down::after
      cursor: pointer

    .custom-input>.v-input> .v-input__control>.v-input__slot:before
      border-style: none
      text-align: center
      justify-content: center
      cursor: pointer

    .custom-input>.v-input__control>.v-input__slot:before
      text-align: center
      justify-content: center
      border-style: none
      cursor: pointer

    .ex-banner
      z-index: 1

    .v-banner__wrapper
      border-bottom: 0

    .toggle
      text-decoration: none
      text-align: center

  .custom-loader
    animation: loader 1s infinite
    display: flex

  @-moz-keyframes loader
    from
      transform: rotate(0)
    to
      transform: rotate(360deg)
  @-webkit-keyframes loader
    from
      transform: rotate(0)
    to
      transform: rotate(360deg)
  @-o-keyframes loader
    from
      transform: rotate(0)
    to
      transform: rotate(360deg)
  @keyframes loader
    from
      transform: rotate(0)
    to
      transform: rotate(360deg)

</style>
