<template>
  <v-skeleton-loader
    v-if="loading"
    v-bind="skelletonAttrs"
    type="table"
    width="100%"
  />
  <div v-else>
    <v-row class="mb-0">
      <v-col class="ma-0 pa-0 pl-0" cols="12" md="12">
        <!-- TABELA -->
        <table
          class="budget-custom"
          widht="100%"
          cellspacing="0"
          cellpadding="0"
        >
          <thead class="table-header">
            <tr>
              <th class="col-itens rounded-l-lg">Itens de Despesa</th>
              <th class="col-date">Data Pagamento</th>
              <th class="col-cost">Moeda/ha</th>
              <th class="col-currency">Moeda</th>
              <th class="col-brl">BRL</th>
              <th class="col-usd">USD</th>
              <th class="col-growing">{{ growingSelected.name }}*</th>
              <th class="col-actions rounded-r-lg">Ações</th>
            </tr>
          </thead>
          <tbody v-for="category in treedata" :key="category.id">
            <tr class="grouper">
              <td class="pl-5 rounded-l-lg" colspan="4">
                {{ category.name }}
                <!-- Cheat to force Vue update -->
                <input
                  type="hidden"
                  id="custId"
                  :value="forceVueUpdate(category.id)"
                />
              </td>
              <td class="text-center">
                {{ categoryAmountReal(category.id) | formatterUnit(2) }}
              </td>
              <td class="text-center">
                {{ categoryAmountDollar(category.id) | formatterUnit(2) }}
              </td>
              <td class="text-center">
                {{ categoryAmountGrowing(category.id) }} {{ unit }}
              </td>
              <td class="text-center rounded-r-lg"></td>
            </tr>
            <template v-for="subcategory in category.children">
              <tr :key="subcategory.id" style="line-height: 45px">
                <td class="pl-10 py-0">
                  <a
                    href="javascript://"
                    v-on:click="rowClicked(subcategory.index)"
                    class="toggle"
                    :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'"
                  >
                    <span >
                      <span v-if="!rowClickState[subcategory.index]">+</span>
                      <span v-if="rowClickState[subcategory.index]">-</span>
                      {{ subcategory.name }}
                    </span>
                  </a>
                </td>
                <template v-if="subcategory.children.length <= 1">
                  <td class="py-0 text-center">
                    <agr-date-picker
                      class="custom-input"
                      hide-details
                      v-model="subcategory.children[0].date"
                      ref="paDate"
                      v-on:change="
                        manualInputDate(
                          $event,
                          subcategory.children[0],
                          category.id,
                          subcategory.id,
                          0
                        )
                      "
                      hideDetails
                      dense
                    />
                  </td>
                  <td class="py-0">
                    <v-currency-field
                      v-model="subcategory.children[0].value"
                      class="custom-input"
                      :prefix="subcategory.children[0].currency_options.prefix"
                      :suffix="subcategory.children[0].currency_options.suffix"
                      :locale="subcategory.children[0].currency_options.locale"
                      :rules="[rules.required]"
                      hide-details
                      dense
                    />
                  </td>
                  <td class="py-0">
                    <v-autocomplete
                      class="custom-input"
                      v-model="subcategory.children[0].currency_id"
                      ref="outgoing_currency"
                      :items="currenciesFiltered"
                      color="primary"
                      item-text="name"
                      item-value="id"
                      required
                      hide-details
                      dense
                      @change="
                        changeCurrency($event, category.id, subcategory.id, 0)
                      "
                    />
                  </td>
                </template>
                <td v-else colspan="3"></td>

                <td class="text-center py-0">
                  {{
                    subCategoryAmountReal(subcategory.children)
                      | formatterUnit(2)
                  }}
                </td>
                <td class="text-center py-0">
                  <span>{{
                    subCategoryAmountDollar(subcategory.children)
                      | formatterUnit(2)
                  }}</span>
                </td>
                <td class="text-center py-0">
                  <span
                    >{{ subCategoryAmountGrowing(subcategory.children) }}
                    {{ unit }}</span
                  >
                </td>
                <td></td>
              </tr>
              <template v-if="rowClickState[subcategory.index]">
                <tr
                  v-for="(bud, i) in subcategory.children"
                  :key="`C${bud.index}`"
                >
                  <td></td>
                  <td class="py-0">
                    <agr-date-picker
                      class="custom-input"
                      hide-details
                      v-model="bud.date"
                      ref="paDate"
                      readonly
                      hideDetails
                      dense
                    />
                  </td>
                  <td class="py-0">
                    <v-currency-field
                      v-model="bud.value"
                      class="custom-input"
                      :prefix="bud.currency_options.prefix"
                      :suffix="bud.currency_options.suffix"
                      :locale="bud.currency_options.locale"
                      :rules="[rules.required]"
                      hide-details
                      dense
                    />
                  </td>
                  <td class="py-0">
                    <v-autocomplete
                      class="custom-input"
                      v-model="bud.currency_id"
                      ref="outgoing_currency"
                      :items="currenciesFiltered"
                      color="primary"
                      item-text="name"
                      item-value="id"
                      required
                      hide-details
                      dense
                      @change="
                        changeCurrency($event, category.id, subcategory.id, i)
                      "
                    />
                  </td>
                  <td class="text-center py-0">
                    <span>{{
                      subCategoryAmountRealBudget(bud) | formatterUnit(2)
                    }}</span>
                  </td>
                  <td class="text-center py-0">
                    <v-badge
                      :color="dollarColor(bud.quotes_updated)"
                      dot
                      slot="activator"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            subCategoryAmountDollarBudget(bud).value
                              | formatterUnit(2)
                          }}</span>
                        </template>
                        <span
                          >$
                          {{
                            subCategoryAmountDollarBudget(bud).dollar
                              | formatterUnit(3)
                          }}</span
                        >
                      </v-tooltip>
                    </v-badge>
                  </td>
                  <td class="text-center py-0">
                    <v-badge
                      :color="dollarColor(bud.quotes_updated)"
                      dot
                      slot="activator"
                    >
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on"
                            >{{ subCategoryAmountGrowingBudget(bud).value }}
                            {{ unit }}</span
                          >
                        </template>
                        <span>{{
                          subCategoryAmountGrowingBudget(bud).ppe
                            | formatterReal
                        }}</span>
                      </v-tooltip>
                    </v-badge>
                  </td>
                  <td class="text-center py-0">
                    <v-row justify="center" no-gutters>
                      <v-col sm="2" md="2" lg="2" xl="2">
                        <v-tooltip top dark>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              color="blue"
                              icon
                              v-on="on"
                              class="pa-0"
                              @click="addRow(category.id, subcategory.id)"
                            >
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Adicionar novo lançamento</span>
                        </v-tooltip>
                      </v-col>
                      <v-col sm="2" md="2" lg="2" xl="2">
                        <agr-delete-button
                          icon
                          :item="bud"
                          @confirmDelete="
                            confirmDelete(category.id, subcategory.id, bud)
                          "
                        />
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr :key="`B${subcategory.id}`">
                  <td colspan="8">
                    <v-row justify="center" no-gutters>
                      <v-col cols="4" sm="5" md="4" lg="3" xl="3">
                        <v-row no-gutters>
                          <v-col
                            cols="4"
                            sm="3"
                            md="3"
                            lg="3"
                            xl="2"
                            class="
                              px-1
                              template-color-2
                              white--text
                              font-weight-bold
                              rounded-l-xl
                            "
                          >
                            <v-text-field
                              v-model="subcategory.copy"
                              prepend-icon="mdi-content-copy"
                              type="number"
                              dense
                              reverse
                              hide-details
                            />
                          </v-col>
                          <v-col
                            cols="9"
                            sm="8"
                            md="9"
                            lg="9"
                            xl="6"
                            class="py-2 template-color-1 rounded-r-xl"
                          >
                            <a
                              href="javascript://"
                              v-on:click="
                                copy(
                                  category.id,
                                  subcategory.id,
                                  subcategory.copy
                                )
                              "
                              class="toggle white--text font-weight-bold"
                            >
                              <span class="pl-2"
                                >Copiar últimos lançamentos</span
                              >
                            </a>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
          <tbody>
            <tr class="grouper">
              <td class="pl-5 rounded-l-lg" colspan="4">Custo total</td>
              <td class="text-center">{{ AmountReal | formatterUnit(2) }}</td>
              <td class="text-center">{{ AmountDollar | formatterUnit(2) }}</td>
              <td class="text-center">{{ AmountGrowing }} {{ unit }}</td>
              <td class="text-center rounded-r-lg"></td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col sm="9" md="12" lg="9" xl="4" class="text-right mt-3">
        <span class="white--text info rounded-pill pa-2">
          *O calculo do preço da saca é baseado na Paridade de Exportação (PPE)
        </span>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      mix-height="90vh"
      max-width="50vw"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="close"
    >
      <v-card pt-3>
        <v-card-title
          class="form-header headline pa-2 font-weight-medium white--text"
        >
          Frete da propriedade
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <span class="py-2 text-h3">
                Identificamos que não existe frete cadastrado para esta
                propriedade. <br />
                Deseja cadastrar agora?
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" no-gutters>
            <v-col class="text-right firefox-align-end">
              <v-btn class="ma-1" color="error" @click="dialog = false">
                Não
              </v-btn>
              <v-btn class="ma-1" color="primary" @click="toShipping">
                Sim
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { real_op, dollar_op, sack_op_suf } from "@/currencyOptions";
import { eventBus } from "@/main";
import { utilsService } from '@/services/utils';
import { DecimalPrecision } from '@/utilsNumber';
import { pick } from '@/utilsObject';
import { addMonth } from '@/utilsDate';

import FormMixins from "@/mixins/form";
import constants from "@/constants";
import Business from "@/utilsBusiness";

export default {
  name: "POS",
  props: {
    ownershipSelected: Number,
    harvestSelected: Object,
    growingSelected: Object,
    ownershipReference: String,
  },
  mixins: [FormMixins],
  data() {
    return {
      dialog: false,
      loading: false,
      budget: {
        value: 0,
        date: undefined,
        subcategory_id: undefined,
        ownership_id: undefined,
        harvest_id: undefined,
        growing_id: undefined,
        currency_id: 1,
      },
      // Array utilizado para atualizar o dolar e PPE
      budgets: [],
      // Array principal
      // Contem todos os budgets configurados
      treedata: [],
      newBudget: {
        date: undefined,
        value: 0,
      },
      loader: undefined,
      // Quotações de hoje
      // Buscadas no load da pagina
      quotes: undefined,
      // Dados da logistica da fazenda
      logistic: undefined,
      // Constantes das culturas
      constants: undefined,
      // Ordem de inserção de cada budget nas categorias
      // identificador unico na tabela
      indexBudget: 0,
      // Conf das moedas
      options: real_op,
      // array que contem a linha selecionada para expandir
      rowClickState: [],
      // Conf do skeleton (load)
      skelletonAttrs: {
        class: "mb-6",
        elevation: 0,
      },
      client_id: undefined,
    };
  },
  computed: {
    categories() {
      // filtra as categorias pelo id da cultura
      return this.outgoingCategories.filter(
        (c) => c.growing_id === this.growingSelected.id
      );
    },
    // Retorna a unidade
    // de acordo com a cultura
    unit() {
      if (this.growingSelected.symbol === "CT") {
        return "@";
      }
      return "sc";
    },
    // Soma da linha expandida (R$)
    subCategoryAmountRealBudget() {
      return (budget) => {
        let sum = 0;
        if (budget.currency_identifier === "R") sum += parseFloat(budget.value);
        else if (budget.currency_identifier === "D")
          sum += parseFloat(budget.value) * budget.quotes.dollar.value;
        else
          sum +=
            parseFloat(budget.value) *
            this.subCategoryAmountGrowingBudget(budget).ppe;

        return sum;
      };
    },
    // Soma da da subcategoria (R$)
    subCategoryAmountReal() {
      return (budgets) => {
        let sum = 0;
        budgets.forEach((b) => {
          if (b.currency_identifier === "R") sum += parseFloat(b.value);
          else if (b.currency_identifier === "D")
            sum += parseFloat(b.value) * b.quotes.dollar.value;
          else
            sum +=
              parseFloat(b.value) * this.subCategoryAmountGrowingBudget(b).ppe;
        });
        return sum;
      };
    },
    // Soma da categoria (R$)
    categoryAmountReal() {
      return (id) => {
        let sum = 0;
        this.treedata
          .find((cat) => cat.id === id)
          .children.forEach((sub) => {
            sub.children.forEach((b) => {
              if (b.currency_identifier === "R") sum += parseFloat(b.value);
              else if (b.currency_identifier === "D")
                sum += parseFloat(b.value) * b.quotes.dollar.value;
              else
                sum +=
                  parseFloat(b.value) *
                  this.subCategoryAmountGrowingBudget(b).ppe;
            });
          });

        return sum;
      };
    },
    // Computed para forçar Vue
    // atualizar o dolar
    forceVueUpdate() {
      return (id) => {
        let sum = 0;
        this.groupSubCategories(id).forEach((sub) => {
          this.budgets.forEach((b) => {
            if (b.subcategory_id === sub.id) {
              if (b.currency_identifier === "R") sum += parseFloat(b.value);
              else sum += parseFloat(b.value) * b.quotes.dollar.value;
            }
          });
        });
        return sum;
      };
    },
    // Soma da linha expandida ($)
    subCategoryAmountDollarBudget() {
      return (budget) => {
        let sum = 0;
        if (budget.currency_identifier === "D") sum += parseFloat(budget.value);
        else if (budget.currency_identifier === "R")
          sum += parseFloat(budget.value) / budget.quotes.dollar.value;
        else
          sum +=
            (parseFloat(budget.value) *
              this.subCategoryAmountGrowingBudget(budget).ppe) /
            budget.quotes.dollar.value;

        return { value: sum, dollar: budget.quotes.dollar.value };
      };
    },
    // Soma da subcategoria ($)
    subCategoryAmountDollar() {
      return (budgets) => {
        let sum = 0;
        budgets.forEach((b) => {
          if (b.currency_identifier === "D") sum += parseFloat(b.value);
          else if (b.currency_identifier === "R")
            sum += parseFloat(b.value) / b.quotes.dollar.value;
          else
            sum +=
              (parseFloat(b.value) *
                this.subCategoryAmountGrowingBudget(b).ppe) /
              b.quotes.dollar.value;
        });
        return sum;
      };
    },
    // Soma da categoria ($)
    categoryAmountDollar() {
      return (id) => {
        let sum = 0;
        this.treedata
          .find((cat) => cat.id === id)
          .children.forEach((sub) => {
            sub.children.forEach((b) => {
              if (b.currency_identifier === "D") sum += parseFloat(b.value);
              else if (b.currency_identifier === "R")
                sum += parseFloat(b.value) / b.quotes.dollar.value;
              else
                sum +=
                  (parseFloat(b.value) *
                    this.subCategoryAmountGrowingBudget(b).ppe) /
                  b.quotes.dollar.value;
            });
          });

        return sum;
      };
    },
    // Soma da categoria (PPE)
    categoryAmountGrowing() {
      return (id) => {
        if (this.logistic && this.logistic.value) {
          let sum = 0;
          let ppe = 0;
          this.treedata
            .find((cat) => cat.id === id)
            .children.forEach((sub) => {
              sub.children.forEach((b) => {
                if (this.growingSelected.symbol === "CT") {
                  ppe = Business.ppeRealArroba(
                    (b.quotes.se.value + b.quotes.basis.value).toFixed(4),
                    this.constants,
                    this.logistic.value,
                    this.logistic.stuffing,
                    b.quotes.dollar.value
                  );
                } else {
                  ppe = Business.ppeRealSack(
                    (b.quotes.se.value + b.quotes.basis.value).toFixed(4),
                    this.constants.t,
                    this.logistic.value,
                    this.logistic.fobbings,
                    b.quotes.dollar.value
                  );
                }
                if (b.currency_identifier === "R")
                  sum += parseFloat(b.value) / ppe;
                else if (b.currency_identifier === "D")
                  sum += (parseFloat(b.value) * b.quotes.dollar.value) / ppe;
                else sum += parseFloat(b.value);
              });
            });
          return DecimalPrecision().round(sum, 2);
        } else {
          return 0;
        }
      };
    },
    // Soma da linha expandida (PPE)
    subCategoryAmountGrowingBudget() {
      return (budget) => {
        if (this.logistic && this.logistic.value) {
          let sum = 0;
          let ppe = 0;
          if (this.growingSelected.symbol === "CT") {
            ppe = Business.ppeRealArroba(
              (budget.quotes.se.value + budget.quotes.basis.value).toFixed(4),
              this.constants,
              this.logistic.value,
              this.logistic.stuffing,
              budget.quotes.dollar.value
            );
          } else {
            ppe = Business.ppeRealSack(
              (budget.quotes.se.value + budget.quotes.basis.value).toFixed(4),
              this.constants.t,
              this.logistic.value,
              this.logistic.fobbings,
              budget.quotes.dollar.value
            );
          }
          if (budget.currency_identifier === "R")
            sum += parseFloat(budget.value) / ppe;
          else if (budget.currency_identifier === "D")
            sum +=
              (parseFloat(budget.value) * budget.quotes.dollar.value) / ppe;
          else sum += parseFloat(budget.value);
          return { value: DecimalPrecision().round(sum, 2), ppe: ppe };
        } else {
          return { value: 0, ppe: 0 };
        }
      };
    },
    // Soma da subcategoria (PPE)
    subCategoryAmountGrowing() {
      return (budgets) => {
        if (this.logistic && this.logistic.value) {
          let sum = 0;
          let ppe = 0;
          budgets.forEach((b) => {
            if (this.growingSelected.symbol === "CT") {
              ppe = Business.ppeRealArroba(
                (b.quotes.se.value + b.quotes.basis.value).toFixed(4),
                this.constants,
                this.logistic.value,
                this.logistic.stuffing,
                b.quotes.dollar.value
              );
            } else {
              ppe = Business.ppeRealSack(
                (b.quotes.se.value + b.quotes.basis.value).toFixed(4),
                this.constants.t,
                this.logistic.value,
                this.logistic.fobbings,
                b.quotes.dollar.value
              );
            }
            if (b.currency_identifier === "R") sum += parseFloat(b.value) / ppe;
            else if (b.currency_identifier === "D")
              sum += (parseFloat(b.value) * b.quotes.dollar.value) / ppe;
            else sum += parseFloat(b.value);
          });
          return DecimalPrecision().round(sum, 2);
        } else {
          return 0;
        }
      };
    },
    // Total de todas categorias (R$)
    AmountReal() {
      let sum = 0;
      // Just a cheat to force update with quotations
      let test = this.budgets;
      this.treedata.forEach((cat) => {
        cat.children.forEach((sub) => {
          sub.children.forEach((b) => {
            if (b.currency_identifier === "R") sum += parseFloat(b.value);
            else if (b.currency_identifier === "D")
              sum += parseFloat(b.value) * b.quotes.dollar.value;
            else
              sum +=
                parseFloat(b.value) *
                this.subCategoryAmountGrowingBudget(b).ppe;
          });
        });
      });
      return sum;
    },
    // Total de todas categorias ($)
    AmountDollar() {
      let sum = 0;
      // Just a cheat to force update with quotations
      let test = this.budgets;
      this.treedata.forEach((cat) => {
        cat.children.forEach((sub) => {
          sub.children.forEach((b) => {
            if (b.currency_identifier === "D") sum += parseFloat(b.value);
            else if (b.currency_identifier === "R")
              sum += parseFloat(b.value) / b.quotes.dollar.value;
            else
              sum +=
                (parseFloat(b.value) *
                  this.subCategoryAmountGrowingBudget(b).ppe) /
                b.quotes.dollar.value;
          });
        });
      });
      return sum;
    },
    // Total de todas categorias (PPE)
    AmountGrowing() {
      if (this.logistic && this.logistic.value) {
        let sum = 0;
        let ppe = 0;
        // Just a cheat to force update with quotations
        let test = this.budgets;
        this.treedata.forEach((cat) => {
          cat.children.forEach((sub) => {
            sub.children.forEach((b) => {
              if (this.growingSelected.symbol === "CT") {
                ppe = Business.ppeRealArroba(
                  (b.quotes.se.value + b.quotes.basis.value).toFixed(4),
                  this.constants,
                  this.logistic.value,
                  this.logistic.stuffing,
                  b.quotes.dollar.value
                );
              } else {
                ppe = Business.ppeRealSack(
                  (b.quotes.se.value + b.quotes.basis.value).toFixed(4),
                  this.constants.t,
                  this.logistic.value,
                  this.logistic.fobbings || this.logistic.fobbings,
                  b.quotes.dollar.value
                );
              }
              if (b.currency_identifier === "R")
                sum += parseFloat(b.value) / ppe;
              else if (b.currency_identifier === "D")
                sum += (parseFloat(b.value) * b.quotes.dollar.value) / ppe;
              else sum += parseFloat(b.value);
            });
          });
        });
        return DecimalPrecision().round(sum, 2);
      } else {
        return 0;
      }
    },
    // Nome da cultura em ingles
    growingEnglishName() {
      return Business.growingEnglishName(this.growingSelected.symbol);
    },
    // Cria um array com moedas personalisadas
    currenciesFiltered() {
      if (this.growingSelected.symbol == "S")
        return this.currencies.filter(
          (c) =>
            c.identifier === "R" ||
            c.identifier === "D" ||
            c.identifier === "SS"
        );
      else if (this.growingSelected.symbol == "C")
        return this.currencies.filter(
          (c) =>
            c.identifier === "R" ||
            c.identifier === "D" ||
            c.identifier === "SM"
        );
      else if (this.growingSelected.symbol == "CT")
        return this.currencies.filter(
          (c) =>
            c.identifier === "R" ||
            c.identifier === "D" ||
            c.identifier === "AA"
        );

      return this.currencies.filter(
        (c) =>
          c.identifier === "R" || c.identifier === "D" || c.identifier === "SS"
      );
    },
    // Retorna se o budget tem como moedas sacas
    sackSelected() {
      return (budget) => {
        let currency = Business.getCurrencyById(budget.currency_id);

        if (currency.identifier === "SS" || currency.identifier === "SM")
          return true;
        else return false;
      };
    },
  },
  methods: {
    async searchBudgets() {
      this.loading = true;
      this.$emit("ready", false);
      // Carrega as constantes das culturas e as cotações de hoje
      this.constants = constants.get(this.growingSelected.symbol);
      // Pega a logistica da fazenda
      let params = {
        ownership_id: this.ownershipSelected,
        harvest_id: this.harvestSelected.id,
        growing_id: this.growingSelected.id,
      };
      let result = await utilsService.getLogistics(params);
      if (
        Object.prototype.hasOwnProperty.call(result, "value") &&
        result.value
      ) {
        this.logistic = result;
      } else {
        this.dialog = true;
        this.logistic = undefined;
      }
      params.checkDelete = true;
      let payload = {
        resource: "budget",
        params: params,
      };
      // Busca os budgets na API
      await this.search(payload);
      this.loading = false;
      // Com os budgets, separas nas categorias e faz os calculos
      this.$nextTick(() => {
        this.throwBudgetInCategories();
      });
    },
    groupSubCategories(id) {
      return this.subOutgoingType.filter(
        (item) => item.outgoing_category.id === id
      );
    },
    changeCurrency(ev, category_id, subcategory_id, index) {
      let currency = Business.getCurrencyById(ev);
      let currency_options = null;

      if (currency.identifier === "D") {
        currency_options = dollar_op;
      } else if (currency.identifier === "R") {
        currency_options = real_op;
      } else if (["SS", "SM", "AA"].includes(currency.identifier)) {
        sack_op_suf.suffix = this.unit;
        currency_options = sack_op_suf;
      } else {
        currency_options = real_op;
      }
      if (currency_options) {
        let bud = this.treedata
          .find((td) => td.id === category_id)
          .children.find((sub) => sub.id === subcategory_id).children[index];
        bud.currency_options = currency_options;
        bud.currency_identifier = currency.identifier;
        this.treedata
          .find((td) => td.id === category_id)
          .children.find((sub) => sub.id === subcategory_id)
          .children.splice(index, 1, bud);
      }
    },
    selectOption(currency) {
      switch (currency.identifier) {
        case "D":
          return dollar_op;
        case "R":
          return real_op;
        case "SS":
        case "SM":
        case "AA":
          sack_op_suf.suffix = this.unit;
          return sack_op_suf;

        default:
          return real_op;
      }
    },
    throwBudgetInCategories() {
      this.indexBudget = 0;
      // cada linha da tabela deve
      // ter um index unico
      let rowIndex = 0;
      this.treedata = this.categories.map((category) => {
        return {
          id: category.id,
          name: category.name,
          children: this.groupSubCategories(category.id).map((sub, index) => {
            let buds = this.budgets_result.objects.filter(
              (b) => b.subcategory_id === sub.id
            );
            if (buds.length) {
              buds = buds.map((b) => {
                b.currency_options = this.selectOption(b.currency);
                b.currency_identifier = b.currency.identifier;
                b.quotes = this.quotes;
                b.quotes_updated = false;
                b.index = this.indexBudget;
                b.value = parseFloat(b.value);
                this.indexBudget += 1;
                return b;
              });
            } else {
              buds = [this.emptyBudget(sub.id)];
              buds[0].index = this.indexBudget;
              this.indexBudget += 1;
            }
            let row = {
              id: sub.id,
              name: sub.name,
              index: rowIndex,
              children: buds,
              copy: 1,
            };
            rowIndex += 1;
            return row;
          }),
        };
      });
      // Zera o array para novas insercoes
      this.budgets = [];
      this.rowClickState = [];
      this.categories.forEach((category) => {
        this.groupSubCategories(category.id).forEach((sub) => {
          let bud = this.budgets_result.objects.filter(
            (b) => b.subcategory_id === sub.id
          );
          if (bud.length > 0) {
            bud.forEach((b) => {
              b.currency_options = this.selectOption(b.currency);
              b.currency_identifier = b.currency.identifier;
              b.quotes = this.quotes;
              this.budgets.push(b);
            });
          } else {
            this.budgets.push(this.emptyBudget(sub.id));
          }
        });
      });
      this.$emit("ready", true);
      this.updateDollarByDate();
    },
    agr_alert(msg, isSuccess = true) {
      isSuccess ? this.$alertSuccess(msg) : this.$alertError(msg);
    },
    async save() {
      this.$emit("setOverlay", true);
      let resources = [];
      this.treedata.forEach((category) => {
        category.children.forEach((sub) => {
          sub.children.forEach((b, index) => {
            // Verifica se o budget já foi salvo para atualizacao
            // ou se um novo foi alterado para salvar
            let budget_to_save = null;
            if (b.id) {
              budget_to_save = pick(b, [
                "id",
                "value",
                "date",
                "subcategory_id",
                "ownership_id",
                "harvest_id",
                "growing_id",
                "currency_id",
              ]);
            } else if (b.value !== 0 && !b.ownership_id) {
              budget_to_save = pick(b, [
                "value",
                "date",
                "subcategory_id",
                "ownership_id",
                "harvest_id",
                "growing_id",
                "currency_id",
              ]);
              budget_to_save.ownership_id = this.ownershipSelected;
              budget_to_save.harvest_id = this.harvestSelected.id;
              budget_to_save.growing_id = this.growingSelected.id;
              budget_to_save.date = this.dateISONotNull(
                budget_to_save.date,
                false
              );
            }
            if (budget_to_save) {
              resources.push({
                obj: budget_to_save,
                index: {
                  category_id: category.id,
                  subcategory_id: sub.id,
                  budget: index,
                },
              });
            }
          });
        });
      });
      try {
        await this.saveBudgets(resources);
        this.agr_alert("Previsão orçamentária salva com sucesso!");
        this.$emit("setOverlay", false);
        this.updateDollarByDate();
      } catch (error) {
        this.agr_alert("Houve um problema ao salvar a previsão orçamentária");
        this.$emit("setOverlay", false);
      }
    },
    async saveBudgets(resources) {
      this.setResource("budget");
      await this.asyncForEach(resources, async (resource) => {
        let response = await this.saveResource(resource.obj);
        this.updateBudgetsQuotes(response, resource.index);
      });
    },
    async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
      }
    },
    doSave(e) {
      if (!((e.key === "s" || e.key === "S") && (e.ctrlKey || e.metaKey))) {
        return;
      }
      e.preventDefault();
      this.save();
    },
    async getQuotations(date, growing) {
      let params = { growing: growing, date: date };
      await utilsService.getQuotationsByDate(params).then((res) => {
        this.quotes = res.quotes;
      });
    },
    dollarColor(updated) {
      return updated ? "green" : "red";
    },
    updateDollarByDate() {
      var promise = this.budgets.map((b) => {
        if (b.id) {
          let harvest = this.harvestSelected.is_current ? "actual" : "future";
          let params = {
            growing: this.growingEnglishName,
            date: b.date,
            harvest: harvest,
            reference: this.ownershipReference,
          };
          return utilsService.getQuotationsByDate(params).then((res) => {
            b.quotes = res.quotes;
            b.quotes_updated = true;
            return b;
          });
        }
        return b;
      });
      Promise.all(promise).then((res) => (this.budgets = res));
    },
    confirmDelete(category_id, subcategory_id, item) {
      if (item.id) {
        // Se o budget é salvo no banco deleta
        this.setResource("budget");
        this.deleteResource(item.id)
          .then(() => {
            this.agr_alert("Deletado com sucesso!");
          })
          .catch(() => {
            this.agr_alert("Houve um problema ao deletar", false);
          });
      }
      const index = this.treedata
        .find((td) => td.id === category_id)
        .children.find((sub) => sub.id === subcategory_id)
        .children.findIndex((b) => b.index === item.index);
      // Verifica se o item existe
      if (index > -1) {
        // Verifico se é o primeiro elemento
        if (index) {
          // Se não deleto a linha
          this.treedata
            .find((td) => td.id === category_id)
            .children.find((sub) => sub.id === subcategory_id)
            .children.splice(index, 1);
        } else {
          // Se sim zero os valores da linha
          this.treedata
            .find((td) => td.id === category_id)
            .children.find((sub) => sub.id === subcategory_id)
            .children.splice(index, 1, this.emptyBudget(item.subcategory_id));
        }
      }
    },
    emptyBudget(subcategory_id) {
      return {
        value: 0,
        date: undefined,
        subcategory_id: subcategory_id,
        ownership_id: undefined,
        harvest_id: undefined,
        growing_id: undefined,
        currency_id: 1,
        currency_options: real_op,
        currency_identifier: "R",
        quotes: this.quotes,
        quotes_updated: false,
      };
    },
    updateBudgetsArray(budget) {
      let index = null;
      if (budget && budget.index >= 0) {
        index = budget.index;
      } else {
        index = this.budgets.findIndex(
          (b) => b.subcategory_id === budget.subcategory_id
        );
      }
      if (index) this.$set(this.budgets, index, budget);
    },
    updateBudgetsQuotes(response, index) {
      let bud = this.treedata
        .find((td) => td.id === index.category_id)
        .children.find((sub) => sub.id === index.subcategory_id).children[
        index.budget
      ];
      if (bud || index.budget >= 0) {
        bud.id = response.id;
        bud.date = response.date;
        // atualiza o array de budget
        // para atualizar as cotações
        this.updateBudgetsArray(bud);
        this.treedata
          .find((td) => td.id === index.category_id)
          .children.find((sub) => sub.id === index.subcategory_id)
          .children.splice(index.budget, 1, bud);
      }
    },
    rowClicked(index) {
      this.$set(this.rowClickState, index, !this.rowClickState[index]);
    },
    copy(category_id, subcategory_id, copies) {
      let array = this.treedata
        .find((td) => td.id === category_id)
        .children.find((sub) => sub.id === subcategory_id).children;
      let index = array.length - 1;
      for (var i = 1; i <= copies; i++) {
        let cp = {};
        Object.assign(cp, array[index]);
        cp.index = this.indexBudget;
        this.indexBudget += 1;
        if (cp.date) cp.date = addMonth(cp.date, i);
        else
          cp.date = addMonth(new Date().toISOString().substr(0, 10), i);

        if (cp.id !== null) {
          // Se salvo remove o id do copiado
          delete cp.id;
          delete cp.ownership_id;
        }
        this.treedata
          .find((td) => td.id === category_id)
          .children.find((sub) => sub.id === subcategory_id)
          .children.splice(index + i, 0, cp);
      }
    },
    addRow(category_id, subcategory_id) {
      let newRow = this.emptyBudget(subcategory_id);
      this.budgets.push(newRow);
      newRow.index = this.indexBudget;
      this.indexBudget += 1;
      this.treedata
        .find((td) => td.id === category_id)
        .children.find((sub) => sub.id === subcategory_id)
        .children.splice(1, 0, newRow);
    },
    manualInputDate(date, budget, category_id, subcategory_id, index) {
      if (!budget.date || date !== budget.date) {
        budget.date = date;
        this.treedata
          .find((td) => td.id === category_id)
          .children.find((sub) => sub.id === subcategory_id)
          .children.splice(index, 1, budget);
      }
    },
    toShipping() {
      this.$router.push({
        name: "Fretes",
        params: { ownership_id: this.ownershipSelected },
      });
    },
  },
  async mounted() {
    this.loading = true;
    eventBus.$on("save-budget-pos", () => {
      this.save();
    });
    await this.getQuotations(
      new Date().toISOString().substr(0, 10),
      this.growingEnglishName
    );
    this.searchBudgets();
    document.addEventListener("keydown", this.doSave);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.doSave);
    eventBus.$off("save-budget-pos", () => {
      this.save();
    });
  },
  watch: {
    growingSelected() {
      this.searchBudgets();
    },
    harvestSelected() {
      this.searchBudgets();
    },
    ownershipSelected() {
      this.searchBudgets();
    },
  },
};
</script>

<style>
</style>