import axios from "@/plugins/axios";
import { isValidResponse } from "@/utilsObject";

const getSyncReportSimpleBudget = async (
  growing_id: number,
  harvest_id: number,
  ownership_id: number,
  _extension: string
): Promise<Blob> => {
  const token = localStorage.getItem("USER_TOKEN");
  const response = await axios.httpReport.post(
    "reports/simple_budget",
    {
      data: {
        growing_id: growing_id,
        harvest_id: harvest_id,
        ownership_id: ownership_id,
        extension: _extension,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        "Authentication-Token": token,
      },
      responseType: "blob",
    }
  );
  if (!isValidResponse(response.status))
    console.error(`Error to simple report budget: ${response.statusText}`);
  return response.data;
};

const getSyncReportSimpleBudgetCattle = async (
  cattle_lot_id: number,
  _extension: string
): Promise<Blob> => {
  const token = localStorage.getItem("USER_TOKEN");
  const response = await axios.httpReport.post(
    "reports/simple_budget_cattle",
    {
      data: {
        cattle_lot_id: cattle_lot_id,
        extension: _extension,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        "Authentication-Token": token,
      },
      responseType: "blob",
    }
  );
  if (!isValidResponse(response.status))
    console.error(
      `Error to get simple report budget cattle: ${response.statusText}`
    );
  return response.data;
};

export const reportService = {
  getSyncReportSimpleBudget,
  getSyncReportSimpleBudgetCattle,
};
