<template>
  <div>
    <v-dialog
      v-model="dialog"
      mix-height="90vh"
      max-width="50vw"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="dialog = false"
    >
      <v-card pt-3>
        <v-card-title
          class="form-header headline pa-2 font-weight-medium"
          primary-title
        >
          Lote Boi Gordo
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <span class="py-2 text-h3">
                Identificamos que não existe lote bovino cadastrado para esta
                propriedade. <br />
                O cadastro do lote é necessário para a provisão orçamentário do Boi Gordo.
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row justify="end" no-gutters>
            <v-col class="text-right firefox-align-end">
              <v-btn class="ma-1" color="accent" @click="dialog = false">
                Selecionar outra propriedade
              </v-btn>
              <v-btn class="ma-1" color="primary" @click="toCattleLot">
                Cadastrar lote
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-skeleton-loader
      v-if="loading"
      v-bind="skelletonAttrs"
      type="table"
      width="100%"
    />
    <v-row v-else class="mb-0">
      <v-col class="ma-0 pa-0 pl-0" cols="12" md="12">
        <!-- TABELA -->
        <table
          class="budget-custom green-1"
          widht=100%
          cellspacing="0"
          cellpadding="0"
        >
          <thead class="table-header">
            <tr>
              <th class="col-itens rounded-l-lg">Itens de Despesa</th>
              <th class="col-date">Unidade</th>
              <th class="col-cost">Valor</th>
              <th class="col-currency">R$/Lote</th>
              <th class="col-brl">R$/Cabeça</th>
              <th class="col-usd">R$/@</th>
              <th class="col-actions rounded-r-lg">Ações</th>
            </tr>
          </thead>
          <tbody v-for="category in treedata" :key="category.id">
            <tr :key="category.id" :style="category.name.includes('Outras') ? 'line-height: 55px;' : 'line-height: 45px;'">
              <td class="pl-10 py-0">
                <a
                  href="javascript://"
                  v-on:click="rowClicked(category.id)"
                  class="toggle"
                  :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'"
                >
                  <span>
                    <span v-if="!rowClickState[category.id]">+</span>
                    <span v-if="rowClickState[category.id]">-</span>
                    {{ category.name }}
                  </span>
                </a>
              </td>
              <template v-if="category.children.length <= 1">
                <td class="py-0 text-center">
                  <v-autocomplete
                    v-if="category.name.includes('Outras')"
                    label="Unidade"
                    v-model="category.children[0].unit_id"
                    :items="cattleUnits"
                    color="primary"
                    item-text="name"
                    item-value="id"
                    required
                    hide-details
                    dense/>
                  <span v-else>{{ category.unit_name }}</span>
                </td>
                <td class="py-0">
                  <v-currency-field
                    v-model="category.children[0].value"
                    class="custom-input px-5"
                    :prefix="currencyOp.prefix"
                    :rules="[rules.required]"
                    hide-details
                    dense/>
                </td>
              </template>
              <td v-else colspan="2"></td>

              <td class="text-center py-0">
                  {{ categoryAmountLot(category) | formatterUnit(2) }}
              </td>
              <td class="text-center py-0">
                <span>{{ categoryAmountCabeca(category) | formatterUnit(2) }}</span>
              </td>
              <td class="text-center py-0">
                <span>{{ categoryAmountArroba(category) | formatterUnit(2) }}</span>
              </td>
              <td></td>
            </tr>
            <template  v-if="rowClickState[category.id]">
              <tr v-for="bud in category.children" :key="`C${bud.index}`" :style="category.name.includes('Outras') ? 'line-height: 55px;' : 'line-height: 45px;'">
                <td></td>
                <td class="py-0 text-center">
                  <v-autocomplete
                    v-if="category.name.includes('Outras')"
                    label="Unidade"
                    v-model="bud.unit_id"
                    :items="cattleUnits"
                    color="primary"
                    item-text="name"
                    item-value="id"
                    required
                    hide-details
                    dense/>
                  <span v-else>{{ category.unit_name }}</span>
                </td>
                <td class="py-0">
                  <v-currency-field
                    v-model="bud.value"
                    class="custom-input px-5"
                    :prefix="currencyOp.prefix"
                    :rules="[rules.required]"
                    hide-details
                    clearable
                    dense/>
                </td>
                <td class="text-center py-0">
                  <span>{{ amountLot(bud, category) | formatterUnit(2) }}</span>
                </td>
                <td class="text-center py-0">
                  <span>{{ amountCabeca(bud, category) | formatterUnit(2) }}</span>
                </td>
                <td class="text-center py-0">
                  <span>{{ amountArroba(bud, category) | formatterUnit(2) }}</span>
                </td>
                <td class="text-center py-0">
                  <v-row justify="center" no-gutters>
                    <v-col sm=2 md=2 lg=2 xl=2>
                      <v-tooltip top dark>
                        <template v-slot:activator="{ on }">
                          <v-btn color="blue" icon v-on="on" class="pa-0" @click="addRow(category.id)">
                            <v-icon>mdi-plus-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Adicionar novo lançamento</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm=2 md=2 lg=2 xl=2>
                      <agr-delete-button
                        icon
                        :item="bud"
                        @confirmDelete="confirmDelete(category.id, bud)"/>
                    </v-col>
                  </v-row>
                </td>
              </tr>
              <tr :key="`B${category.id}`">
                <td colspan="8">
                  <v-row justify="center" no-gutters>
                    <v-col cols=4 sm=5 md=4 lg=3 xl=3>
                      <v-row no-gutters>
                        <v-col cols=4 sm=3 md=3 lg=3 xl=2 class="px-1 template-color-2 white--text font-weight-bold rounded-l-xl">
                          <v-text-field
                            v-model="category.copy"
                            prepend-icon="mdi-content-copy"
                            type="number"
                            dense
                            reverse
                            hide-details/>

                        </v-col>
                        <v-col cols=9 sm=8 md=9 lg=9 xl=6 class="py-2 template-color-1 rounded-r-xl">
                          <a href="javascript://" v-on:click="copy(category.id, category.copy)" class="toggle white--text font-weight-bold">
                            <span class="pl-2">Copiar últimos lançamentos</span>
                          </a>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody>
            <tr class="grouper" style="line-height: 35px;">
              <td class="pl-5 rounded-l-lg" colspan="3">
                Custo total
              </td>
              <td class="text-center">{{ AmountLot | formatterUnit(2) }}</td>
              <td class="text-center">{{ AmountCabeca | formatterUnit(2) }}</td>
              <td class="text-center">{{ AmountArroba | formatterUnit(2) }}</td>
              <td class="text-center rounded-r-lg"></td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormMixins from "@/mixins/form";
import { real_op } from "@/currencyOptions";
import { eventBus } from "@/main";

import { pick } from '@/utilsObject';
import { addMonth } from '@/utilsDate';

export default {
  name: "POP",
  mixins: [FormMixins],
  props: {
    ownershipSelected: Number,
    growingSelected: Object,
    cattleLotsSelected: Object,
    ownershipReference: String,
  },
  data() {
    return {
      // load dos budgets
      loading: false,
      // dialog para load sem lote
      dialog: false,
      // Array principal
      // Contem todos os budgets configurados
      treedata: [],
      // array que contem a linha selecionada para expandir
      rowClickState: [],
      // unidade da cultura
      unit: "@",
      // Conf do skeleton (load)
      skelletonAttrs: {
        class: "mb-6",
        elevation: 0,
      },
      // Conf do moeda
      currencyOp: real_op,
    };
  },
  methods: {
    emptyBudget(category_id) {
      return {
        value: 0,
        date: undefined,
        category_id: category_id,
        ownership_id: undefined,
        growing_id: undefined,
        currency_id: this.currencyRealId,
        unit_id: undefined,
        cattle_lot_id: this.cattleLotsSelected.id,
      };
    },
    async searchBudgets() {
      this.loading = true;
      this.$emit("ready", false);
      let payload = {
        resource: "budget",
        params: {
          ownership_id: this.ownershipSelected,
          growing_id: this.growingSelected.id,
          cattle_lot_id: this.cattleLotsSelected.id,
          checkDelete: true,
        },
      };
      // Busca os budgets na API
      await this.search(payload);
      this.loading = false;
      this.$nextTick(() => {
        this.throwBudgetInCategories();
      });
    },
    throwBudgetInCategories() {
      // Com os budgets, separas nas categorias e faz os calculos
      this.indexBudget = 0;
      this.treedata = this.categories
        .filter((category) => {
          // faz o filtro para retirar a categoria 'custo de recria'
          // para quando o lote for o ciclo de recreia e terminação
          if (
            this.cattleLotsSelected.cycle === "BREED_FINISHING" &&
            category.name.toLowerCase().replace(/\s/g, "") === "custocomrecria"
          ) {
            return false;
          }
          return true;
        })
        .map((category) => {
          let buds = this.budgets_result.objects.filter(
            (b) => b.category_id === category.id
          );
          let unit = this.tieupUnitCategory(category.name);
          if (buds.length) {
            buds = buds.map((b) => {
              b.index = this.indexBudget;
              b.value = parseFloat(b.value);
              this.indexBudget += 1;
              return b;
            });
          } else {
            buds = [this.emptyBudget(category.id)];
            buds[0].unit_id = unit.id;
            buds[0].index = this.indexBudget;
            this.indexBudget += 1;
          }
          return {
            id: category.id,
            name: category.name,
            children: buds,
            unit_id: unit.id,
            unit_name: unit.name,
            unit_symbol: unit.symbol,
            cycle: this.classifyByCicle(category.name),
            copy: 1,
          };
        });
      // Zera o array para novas insercoes
      this.budgets = [];
      this.rowClickState = [];
      this.categories.forEach((category) => {
        let bud = this.budgets_result.objects.filter(
          (b) => b.category_id === category.id
        );
        if (bud.length > 0) {
          bud.forEach((b) => {
            this.budgets.push(b);
          });
        } else {
          this.budgets.push(this.emptyBudget(category.id));
        }
      });
      this.$emit("ready", true);
    },
    updateBudgetsArray(budget) {
      let index = null;
      if (budget && budget.index >= 0) {
        index = budget.index;
      } else {
        index = this.budgets.findIndex(
          (b) => b.category_id === budget.category_id
        );
      }
      if (index) this.$set(this.budgets, index, budget);
    },
    async save() {
      this.$emit("setOverlay", true);
      let resources = [];
      let unitError = false;
      let categoryNameError = undefined;
      this.treedata.forEach((category) => {
        category.children.forEach((b, index) => {
          // Verifica se o budget já foi salvo para atualizacao
          // ou se um novo foi alterado para salvar
          let budget_to_save = null;
          if (b.id) {
            budget_to_save = pick(b, [
              "id",
              "value",
              "unit_id",
              "category_id",
              "ownership_id",
              "growing_id",
              "currency_id",
              "cattle_lot_id",
            ]);
          } else if (b.value !== 0 && !b.ownership_id) {
            budget_to_save = pick(b, [
              "value",
              "unit_id",
              "category_id",
              "ownership_id",
              "growing_id",
              "currency_id",
              "cattle_lot_id",
            ]);
            budget_to_save.ownership_id = this.ownershipSelected;
            budget_to_save.growing_id = this.growingSelected.id;
          }
          if (budget_to_save) {
            // Check para ver se a categoria outros
            // foi digitada, mas não selecionada a unidade
            if (budget_to_save.unit_id) {
              // cria um meta obj
              // obj: budget para ser salvo
              // location: coordenadas para localizar o budget no treedata
              resources.push({
                obj: budget_to_save,
                location: {
                  category_id: category.id,
                  budget_position: index,
                },
              });
            } else {
              unitError = true;
              categoryNameError = category.name;
            }
          }
        });
      });
      // Se houve erro não salva e mostra msg
      if (!unitError) {
        this.setResource("budget");
        const loopSave = async (resources) => {
          try {
            for (let resource of resources) {
              let response = await this.saveResource(resource.obj);
              let bud = this.treedata.find(
                (td) => td.id === resource.location.category_id
              ).children[resource.location.budget_position];
              if (bud || resource.location.budget_position >= 0) {
                // insere o id no budget da tela
                bud.id = response.id;
                // atualiza o array de budget
                // para atualizar as cotações
                this.updateBudgetsArray(bud);
                this.treedata
                  .find((td) => td.id === resource.location.category_id)
                  .children.splice(resource.location.budget_position, 1, bud);
              }
            }
          } catch (err) {
            console.log(err);
            throw err;
          }
        };
        loopSave(resources)
          .then(() => {
            this.agr_alert("Previsão orçamentária salva com sucesso!");
          })
          .catch(() => {
            this.agr_alert(
              "Houve um problema ao salvar a previsão orçamentária",
              false
            );
          })
          .finally(() => {
            this.$emit("setOverlay", false);
          });
      } else {
        this.$alertError(
          `Selecione uma unidade para o lançamento na categoria: "${categoryNameError}"`
        );
        this.$emit("setOverlay", false);
        return;
      }
    },
    // Mapeia as teclas Ctrl + s
    // para salvar os budgets
    doSave(e) {
      if (!((e.key === "s" || e.key === "S") && (e.ctrlKey || e.metaKey))) {
        return;
      }
      e.preventDefault();
      this.save();
    },
    // Força o Vue a expondir a linha selecionada
    rowClicked(index) {
      this.$set(this.rowClickState, index, !this.rowClickState[index]);
    },
    // Add mais uma linha na tabela
    // ao clicar no btn de +
    addRow(category_id) {
      let newRow = this.emptyBudget(category_id);
      this.budgets.push(newRow);
      newRow.index = this.indexBudget;
      this.indexBudget += 1;
      this.treedata
        .find((td) => td.id === category_id)
        .children.splice(1, 0, newRow);
    },
    confirmDelete(category_id, item) {
      if (item.id) {
        // Se o budget é salvo no banco deleta
        this.setResource("budget");
        this.deleteResource(item.id)
          .then(() => {
            this.agr_alert("Deletado com sucesso!");
          })
          .catch(() => {
            this.agr_alert("Houve um problema ao deletar", false);
          });
      }
      const index = this.treedata
        .find((td) => td.id === category_id)
        .children.findIndex((b) => b.index === item.index);
      // Verifica se o item existe
      if (index > -1) {
        // Verifico se é o primeiro elemento
        if (index) {
          // Se não deleto a linha
          this.treedata
            .find((td) => td.id === category_id)
            .children.splice(index, 1);
        } else {
          // Se sim zero os valores da linha
          this.treedata
            .find((td) => td.id === category_id)
            .children.splice(index, 1, this.emptyBudget(item.category_id));
        }
      }
    },
    // Faz cópias da linha selecionda
    // incrementando a data de cada cópia
    copy(category_id, copies) {
      let array = this.treedata.find((td) => td.id === category_id).children;
      let index = array.length - 1;
      for (var i = 1; i <= copies; i++) {
        let cp = {};
        Object.assign(cp, array[index]);
        cp.index = this.indexBudget;
        this.indexBudget += 1;
        if (cp.date) cp.date = addMonth(cp.date, i);
        else
          cp.date = addMonth(new Date().toISOString().substr(0, 10), i);

        if (cp.id !== null) {
          // Se salvo remove o id do copiado
          delete cp.id;
          delete cp.ownership_id;
        }
        this.treedata
          .find((td) => td.id === category_id)
          .children.splice(index + i, 0, cp);
      }
    },
    // Cada categoria no boi já possui uma unidade definida
    // esta função liga essa unidade com a categoria
    // para 'outras despesas' deixa undefined
    tieupUnitCategory(category_name) {
      if (category_name.includes("Outras"))
        return {
          id: undefined,
          name: undefined,
        };
      if (
        category_name.startsWith("Custo diário") ||
        category_name.startsWith("Suplementação") ||
        category_name.startsWith("Despesa")
      ) {
        let unit = this.AllUnits.find((u) => u.symbol === "cab/dia");
        return {
          id: unit.id,
          symbol: unit.symbol,
          name: `R$/${unit.name}`,
        };
      } else {
        let unit = this.AllUnits.find((u) => u.symbol === "cab");
        return {
          id: unit.id,
          symbol: unit.symbol,
          name: `R$/${unit.name}`,
        };
      }
    },
    // Cada despesa se refere a um ciclo no lote
    // essa função retorna o ciclo de acordo com a categoria
    classifyByCicle(category_name) {
      switch (category_name.toLowerCase().replace(/\s/g, "")) {
        case "freteatéaterminação":
          return "finishing";
        case "custodiáriodadietanaterminação":
          return "finishing";
        case "despesaoperacionalnaterminação":
          return "finishing";
        case "manutenções&depreciações":
          return "finishing";
        case "outrasdespesasterminação":
          return "finishing";
        case "custocomrecria":
          return "finishing";

        default:
          return "breed";
      }
    },
    agr_alert(msg, isSuccess = true) {
      isSuccess ? this.$alertSuccess(msg) : this.$alertError(msg);
    },
    toCattleLot() {
      this.$router.push({
        name: "Lote",
        params: { ownership_id: this.ownershipSelected },
      });
    },
  },
  computed: {
    categories() {
      // filtra as categorias pelo id da cultura
      // retorna todas as categorias dos ciclos
      if (this.cattleLotsSelected.cycle === "BREED_FINISHING")
        return this.outgoingCategories.filter(
          (c) => c.growing_id === this.growingSelected.id
        );
      // Se é só o ciclo terminção
      // retorna só categorias de terminação
      return this.outgoingCategories.filter((c) => {
        if (c.growing_id === this.growingSelected.id) {
          return this.classifyByCicle(c.name) === "finishing";
        }
        return false;
      });
    },
    // Devolve o ID da moeda Real
    currencyRealId() {
      return this.currencies.find((c) => c.identifier === "R").id;
    },
    // Cria um array de unidades personalizadas para o Boi
    cattleUnits() {
      return this.AllUnits.filter((u) =>
        ["cab", "cab/dia"].includes(u.symbol)
      ).map((u) => {
        let { name, ...newUnit } = u;
        return { name: `R$/${name}`, ...newUnit };
      });
    },
    // Soma da linha expandida
    // R$/Lote
    amountLot() {
      if (this.cattleLotsSelected) {
        return (budget, category) => {
          const calculation = function (symbol, cycle, cattleLotsSelected) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                return (
                  parseFloat(budget.value) *
                  parseFloat(cattleLotsSelected.amount) *
                  parseFloat(cattleLotsSelected.breed_period)
                );
              else
                return (
                  parseFloat(budget.value) *
                  parseFloat(cattleLotsSelected.amount) *
                  parseFloat(cattleLotsSelected.finishing_period)
                );
            } else {
              return (
                parseFloat(budget.value) * parseFloat(cattleLotsSelected.amount)
              );
            }
          };
          let symbol = category.unit_symbol;
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            let budUnit = this.cattleUnits.find((u) => u.id === budget.unit_id);
            if (!budUnit) return undefined;
            return calculation(
              budUnit.symbol,
              category.cycle,
              this.cattleLotsSelected
            );
          }
          // Senão é uma categoria normal
          // com apenas uma unidade definida
          return calculation(symbol, category.cycle, this.cattleLotsSelected);
        };
      }
      return 0;
    },
    // Soma da linha expandida
    // R$/Cabeça
    amountCabeca() {
      if (this.cattleLotsSelected) {
        return (budget, category) => {
          const calculation = function (symbol, cycle, cattleLotsSelected) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                return (
                  parseFloat(budget.value) *
                  parseFloat(cattleLotsSelected.breed_period)
                );
              else
                return (
                  parseFloat(budget.value) *
                  parseFloat(cattleLotsSelected.finishing_period)
                );
            } else {
              return parseFloat(budget.value);
            }
          };
          let symbol = category.unit_symbol;
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            let budUnit = this.cattleUnits.find((u) => u.id === budget.unit_id);
            if (!budUnit) return undefined;
            return calculation(
              budUnit.symbol,
              category.cycle,
              this.cattleLotsSelected
            );
          }
          // Senão é uma categoria normal
          // com apenas uma unidade definida
          return calculation(symbol, category.cycle, this.cattleLotsSelected);
        };
      }
      return 0;
    },
    // Soma da linha expandida
    // R$/Arroba
    amountArroba() {
      if (this.cattleLotsSelected) {
        return (budget, category) => {
          const calculation = function (symbol, cycle, cattleLotsSelected) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                return (
                  (parseFloat(budget.value) *
                    parseFloat(cattleLotsSelected.breed_period)) /
                  cattleLotsSelected.final_weight
                );
              else
                return (
                  (parseFloat(budget.value) *
                    parseFloat(cattleLotsSelected.finishing_period)) /
                  cattleLotsSelected.final_weight
                );
            } else {
              return parseFloat(budget.value) / cattleLotsSelected.final_weight;
            }
          };
          let symbol = category.unit_symbol;
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            let budUnit = this.cattleUnits.find((u) => u.id === budget.unit_id);
            if (!budUnit) return undefined;
            return calculation(
              budUnit.symbol,
              category.cycle,
              this.cattleLotsSelected
            );
          }
          // Senão é uma categoria normal
          // com apenas uma unidade definida
          return calculation(symbol, category.cycle, this.cattleLotsSelected);
        };
      }
      return 0;
    },
    // Soma de cada categoria
    // R$/Lote
    categoryAmountLot() {
      if (this.cattleLotsSelected) {
        return (category) => {
          let sum = 0;
          let symbol = category.unit_symbol;
          const calculation = function (
            value,
            symbol,
            cycle,
            cattleLotsSelected
          ) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                return (
                  parseFloat(value) *
                  parseFloat(cattleLotsSelected.amount) *
                  parseFloat(cattleLotsSelected.breed_period)
                );
              else
                return (
                  parseFloat(value) *
                  parseFloat(cattleLotsSelected.amount) *
                  parseFloat(cattleLotsSelected.finishing_period)
                );
            } else {
              return parseFloat(value) * parseFloat(cattleLotsSelected.amount);
            }
          };
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            category.children.forEach((b) => {
              let budUnit = this.cattleUnits.find((u) => u.id === b.unit_id);
              if (!budUnit) return undefined;
              sum += calculation(
                parseFloat(b.value),
                budUnit.symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            return sum;
          }
          // Senão é uma categoria normal
          // com apenas uma unidade definida
          category.children.forEach((b) => {
            sum += calculation(
              parseFloat(b.value),
              symbol,
              category.cycle,
              this.cattleLotsSelected
            );
          });
          return sum;
        };
      }
      return 0;
    },
    // Soma de cada categoria
    // R$/Cabeça
    categoryAmountCabeca() {
      if (this.cattleLotsSelected) {
        return (category) => {
          let sum = 0;
          let symbol = category.unit_symbol;
          const calculation = function (
            value,
            symbol,
            cycle,
            cattleLotsSelected
          ) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                return value * parseFloat(cattleLotsSelected.breed_period);
              else
                return value * parseFloat(cattleLotsSelected.finishing_period);
            }
            return value;
          };
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            category.children.forEach((b) => {
              let budUnit = this.cattleUnits.find((u) => u.id === b.unit_id);
              if (!budUnit) return undefined;
              sum += calculation(
                parseFloat(b.value),
                budUnit.symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            return sum;
          }
          // Senão é uma categoria normal
          // com apenas uma unidade definida
          category.children.forEach((b) => {
            sum += calculation(
              parseFloat(b.value),
              symbol,
              category.cycle,
              this.cattleLotsSelected
            );
          });
          return sum;
        };
      }
      return 0;
    },
    // Soma de cada categoria
    // R$/Arroba
    categoryAmountArroba() {
      if (this.cattleLotsSelected) {
        return (category) => {
          let sum = 0;
          let symbol = category.unit_symbol;
          const calculation = function (
            value,
            symbol,
            cycle,
            cattleLotsSelected
          ) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                value = value * parseFloat(cattleLotsSelected.breed_period);
              else
                value = value * parseFloat(cattleLotsSelected.finishing_period);
            }
            return value / cattleLotsSelected.final_weight;
          };
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            category.children.forEach((b) => {
              let budUnit = this.cattleUnits.find((u) => u.id === b.unit_id);
              if (!budUnit) return undefined;
              sum += calculation(
                parseFloat(b.value),
                budUnit.symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            return sum;
          }
          // Senão é uma categoria normal
          // com apenas uma unidade definida
          category.children.forEach((b) => {
            sum += calculation(
              parseFloat(b.value),
              symbol,
              category.cycle,
              this.cattleLotsSelected
            );
          });
          return sum;
        };
      }
      return 0;
    },
    // Total de todas categorias
    // R$/Lote
    AmountLot() {
      if (this.cattleLotsSelected) {
        let sum = 0;
        this.treedata.forEach((category) => {
          let sumCategory = 0;
          let symbol = category.unit_symbol;
          const calculation = function (
            value,
            symbol,
            cycle,
            cattleLotsSelected
          ) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                return (
                  value *
                  parseFloat(cattleLotsSelected.amount) *
                  parseFloat(cattleLotsSelected.breed_period)
                );
              else
                return (
                  value *
                  parseFloat(cattleLotsSelected.amount) *
                  parseFloat(cattleLotsSelected.finishing_period)
                );
            } else {
              return value * parseFloat(cattleLotsSelected.amount);
            }
          };
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            category.children.forEach((b) => {
              let budUnit = this.cattleUnits.find((u) => u.id === b.unit_id);
              if (!budUnit) return undefined;
              sumCategory += calculation(
                parseFloat(b.value),
                budUnit.symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            sum += sumCategory;
          } else {
            // Senão é uma categoria normal
            // com apenas uma unidade definida
            category.children.forEach((b) => {
              sumCategory += calculation(
                parseFloat(b.value),
                symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            sum += sumCategory;
          }
        });
        return sum;
      }
      return 0;
    },
    // Total de todas categorias
    // R$/Cabeça
    AmountCabeca() {
      if (this.cattleLotsSelected) {
        let sum = 0;
        this.treedata.forEach((category) => {
          let sumCategory = 0;
          let symbol = category.unit_symbol;
          const calculation = function (
            value,
            symbol,
            cycle,
            cattleLotsSelected
          ) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                return value * parseFloat(cattleLotsSelected.breed_period);
              else
                return value * parseFloat(cattleLotsSelected.finishing_period);
            }
            return value;
          };
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            category.children.forEach((b) => {
              let budUnit = this.cattleUnits.find((u) => u.id === b.unit_id);
              if (!budUnit) return undefined;
              sumCategory += calculation(
                parseFloat(b.value),
                budUnit.symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            sum += sumCategory;
          } else {
            // Senão é uma categoria normal
            // com apenas uma unidade definida
            category.children.forEach((b) => {
              sumCategory += calculation(
                parseFloat(b.value),
                symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            sum += sumCategory;
          }
        });
        return sum;
      }
      return 0;
    },
    // Total de todas categorias
    // R$/Arroba
    AmountArroba() {
      if (this.cattleLotsSelected) {
        let sum = 0;
        this.treedata.forEach((category) => {
          let sumCategory = 0;
          let symbol = category.unit_symbol;
          const calculation = function (
            value,
            symbol,
            cycle,
            cattleLotsSelected
          ) {
            if (symbol === "cab/dia") {
              if (cycle === "breed")
                return value * parseFloat(cattleLotsSelected.breed_period);
              else
                return value * parseFloat(cattleLotsSelected.finishing_period);
            }
            return value;
          };
          // Se a categoria não tem unidade
          // ela é outras despesas
          if (!symbol) {
            // para esse calculo deve-se verificar
            // cada unidade de cada linha
            category.children.forEach((b) => {
              let budUnit = this.cattleUnits.find((u) => u.id === b.unit_id);
              if (!budUnit) return undefined;
              sumCategory += calculation(
                parseFloat(b.value),
                budUnit.symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            sum += sumCategory;
          } else {
            // Senão é uma categoria normal
            // com apenas uma unidade definida
            category.children.forEach((b) => {
              sumCategory += calculation(
                parseFloat(b.value),
                symbol,
                category.cycle,
                this.cattleLotsSelected
              );
            });
            sum += sumCategory;
          }
        });
        return sum / this.cattleLotsSelected.final_weight;
      }
      return 0;
    },
  },
  async mounted() {
    this.loading = true;
    eventBus.$on("save-budget-pop", () => {
      this.save();
    });
    if (this.cattleLotsSelected) {
      await this.searchBudgets();
    } else {
      this.$emit("ready", false);
      this.dialog = true;
    }
    document.addEventListener("keydown", this.doSave);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.doSave);
    eventBus.$off("save-budget-pop", () => {
      this.save();
    });
  },
  watch: {
    cattleLotsSelected() {
      this.searchBudgets();
    },
    ownershipSelected() {
      if (this.cattleLotsSelected) {
        this.searchBudgets();
      } else {
        this.$emit("ready", false);
        this.dialog = true;
      }
    },
  },
};
</script>

<style>
</style>